.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000000;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000000;
    color: #fff;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #ffbf00;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  .nav li a {
    text-decoration: none;
    color: #fff;
  }
  
  .subtitle {
    color: #ffbf00;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .slogan {
    color: #f6f6f6;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .main {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .scroll-container {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  
  .section {
    min-width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
  }
  
  .hero {
    display: flex;
    width: 100%;
    align-items: center;
  }
  
  .hero-content {
    
    display: flex;
    flex-direction: column;        
    margin-left: 50px;   
  }
  
  .hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
  }

  .hero-image1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-image1 img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
  }
  
  /* Pagination Dots */
  .pagination-dots {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .dot {
    height: 12px;
    width: 12px;
    background-color: white; /* Inactive dot color */
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #ffbf00; /* Active dot color */
  }

  /* Animation for text */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-text {
  font-size: 2.5rem; /* Adjust the size as needed */
  margin: 10px 0;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
}

.animate-text:nth-child(1) {
  animation-delay: 0.3s;
}

.animate-text:nth-child(2) {
  animation-delay: 0.6s;
}

.animate-text:nth-child(3) {
  animation-delay: 0.9s;
}