.techimg-container {
    width: 130px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    
  }

  .tech-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase elevation on hover */
  }

  .tech-box {
    flex: 1; /* sets a flexible width for each item */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
  }