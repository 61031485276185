
.about-container {
  display: flex;
  flex-direction: column;
  min-height: 150vh;
  background-color: #000000;
}

  .normaltext {
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .main {
    width: 100vw;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .topic {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #454545;
    padding: 10px;
    border-radius: 15px;
    
  }

  .teamdiv  {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #454545;
    padding: 10px;
    border-radius: 15px;
    margin-top: 40px;
    
  }
  .aboutslogan {
    color: #f6f6f6;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .centertitle {
    width: 100vw;    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scroll-container {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  
  .section {
    min-width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
  }
  
  .hero {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .hero-content {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
    padding-left: 0px; /* Decreased space between content and image */
  }
  
  .hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
  }

  .hero-image1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-image1 img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: cover;
  }
  
  /* Pagination Dots */
  .pagination-dots {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .dot {
    height: 12px;
    width: 12px;
    background-color: white; /* Inactive dot color */
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #ffbf00; /* Active dot color */
  }

  /* Animation for text */
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animate-text {
    font-size: 2.5rem; /* Adjust the size as needed */
    margin: 10px 0;
    opacity: 0;
    animation: slideIn 1s ease-in-out forwards;
  }
  
  .animate-text:nth-child(1) {
    animation-delay: 0.3s;
  }
  
  .animate-text:nth-child(2) {
    animation-delay: 0.6s;
  }
  
  .animate-text:nth-child(3) {
    animation-delay: 0.9s;
  }

  .developer-box {
    flex: 1 1 300px; /* sets a flexible width for each item */
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
  }
  
  
  .developer-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase elevation on hover */
  }
  
  .image-container {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    border: 4px solid #fff; /* Optional: Add border around the image */
  }
  
  .rounded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }


  .developer-container {
    display: flex;
    flex-wrap: wrap; /* allows items to wrap if there are too many to fit in one row */
    gap: 20px; /* space between the developer boxes */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  

  .devname {
    margin-bottom: 5px;
    font-size: 16px;
    color: #888;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .devdesignation {
    font-size: 16px;
    color: #888;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .details {
    margin-top: 10px;
    align-items: center;
    width: 100%;
  }