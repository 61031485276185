.contact-component {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Make the container fill the viewport height */
  }
  
  .contact-details {
    text-align: center; /* Center text inside the div */
  }
  
  .online-inquiry, .contact-info {
    flex: 1;
    padding: 20px;
  }
  
  .online-inquiry form {
    display: flex;
    flex-direction: column; /* Align inputs in a column */
  }
  
  .online-inquiry input,
  .online-inquiry select,
  .online-inquiry textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .online-inquiry textarea {
    height: 100px;
  }
  
  .online-inquiry button {
    width: 100%;
    padding: 10px;
    background-color: #6200EE;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .online-inquiry button:hover {
    background-color: #3700B3;
  }
  
  .contact-info h4 {
    margin-bottom: 10px;
  }

  .contact-info {
    align-items: center;
    width: '100%';
    background-color: #6200EE;
    justify-content: center;    
    height: fit-content;
    margin-top: 150px;

  }
  
  .contact-info p {
    margin: 20px 0;
    font-size: 24px;
    color: aliceblue;
    font-weight: 600;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-left: 40px;
    
  }

  .input {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .contact-details {
      flex-direction: column; /* Stack form and contact info on smaller screens */
    }
  
    .online-inquiry, .contact-info {
      width: 100%;
    }
  }

  .subtitlecontact {
    color: #ffbf00;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  